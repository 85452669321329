$rt-namespace: "Toastify";
$rt-toast-width: 480px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 56px !default;
$rt-toast-max-height: 800px !default;

$rt-color-info: #fff !default;
$rt-color-success: #e3f1df !default;
$rt-color-warning: #fcf1cd !default;
$rt-color-error: #fbeae5 !default;

$rt-text-color-info: #2d69dc !default;
$rt-text-color-success: #108043 !default;
$rt-text-color-warning: #c09e06 !default;
$rt-text-color-error: #de3618 !default;

$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-z-index: 9999 !default;
