[data-popper-placement^="top"] .popper-arrow {
  bottom: -3px;
}

[data-popper-placement^="bottom"] .popper-arrow {
  top: -3px;
}

[data-popper-placement^="left"] .popper-arrow {
  right: -3px;
}

[data-popper-placement^="right"] .popper-arrow {
  left: -3px;
}
