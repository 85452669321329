.datepicker {
  text-transform: uppercase;
  font-family: Rubik, ui-sans-serif, system-ui;
  background-color: #fff;
}

.datepicker .DayPicker-Month {
  margin: 0 24px;
}

.datepicker .DayPicker-Day.DayPicker-Day--outside {
  color: #96aab9;
}

.datepicker .DayPicker-Weekday,
.datepicker .DayPicker-Caption {
  color: #96aab9;
  font-size: 12px;
}

.datepicker .DayPicker-Caption {
  color: #627c90;
  padding: 14px 0;
  margin: 0;
}

.datepicker .DayPicker-Weekday {
  border-top: 1px solid #d7dfe9;
}

.datepicker .DayPicker-Day:hover {
  border-radius: 0;
}

/* Date range */
.datepicker
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background: #2658b8;
  color: #fff;
}
.datepicker .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
  background: #2d69dc;
  color: #fff;
}
.datepicker .DayPicker-Day {
  border-radius: 0;
  font-size: 14px;
}
.datepicker .DayPicker-Day--start {
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
}
.datepicker .DayPicker-Day--end {
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
}
.datepicker .DayPicker-Day--today {
  color: #001a2d;
  background: #ebeff2;
  font-weight: 400;
}

.datepicker .DayPicker-NavButton {
  height: 12px;
  width: 14px;
}
.datepicker .DayPicker-NavButton--prev {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAaCAYAAABozQZiAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGNSURBVHgBpZRBTsJAFIbfG3BrPII3QPYIiguIKzyBFdZGutSotEaIuxYPQOsJDDvZGNANO3oD8QZgohvoPKegRJiWFnybTl7n+/83yT8D8I/CqBtPK/fw5dKZG+dNW1P7Xo9FAcuaAZ8uaQRkshE+K5qxHcnZAz/GTAOgyqxJ8MY3KBvqLIFTy20YQWEpXLysVyRwan1jV1UzcOzSdf2YONk+oN64VbXpAGuCvnBUUIJXAefgIFD0TKumquBTE7h0bhQoho8SSPRgVVUFAgq9tLAx9sR6axXQKyZAYx1wAouNQ2kchgQRSsBgiu9grkugFK9MKxS2a6rDiY6kPxEEJtkWOW1zTicBAo0gOPa7cF5bTiKVe0fEwsKenWQ6D72Xp04gvETAy0LGT8D3YigXhsIYWtIhAHUe57p4hmTnKBMgZ5DI5jpOu+UPzwR2c0MhkPcTSO0fdgLhH4FuMp0TAO4tCow4H4S+YZtx0ERe9b89Eaw+d7EZC4O74myJg3wbXeGImPFAciFr35X7390Y2m0a33/qAAAAAElFTkSuQmCC");
}
.datepicker .DayPicker-NavButton--next {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAaCAYAAABozQZiAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFbSURBVHgBrZSxToNAHMb/f9ouPgW+AX2AGuJCOmmc3ARMXBUXB5qIsc60owvFFzCOdaro4tb6Bn0CExcdaHrekbYhcMcdTb+B4bv7fR//IwfArmQHoX7eG16q7m/kQS3FCSCctg+6MH0fJ0pwDtRXvqkSoGXPFI5z4EokcHvDW5A1zz5eP9sdCwHRLKyb1P+Z0nUhzEQ3vHEDEC3D7M5nyfhLCFcEIBI44gU0iml1AkpwnQAuvA4wOtY+IhqiAASJHD+MacBZwSZLIIeaDG619hxCyFPBRg3wWdrMdBE8Ypr+jopvIG3eVAGdtuQptC7Sv4jutPM+TfqWNvNAxhIgJ5Ww6w9GPJBO4MR9LxHC2Y0SgNG9l52+JgTpleSA7hpkwppgnDdxW5CpKQHZuV5H/TLIlM3s+uGVALyLHrwBCJTBywW80A8354ABVGgzs30T6tjECbIfoQLI9A+UBrghqXFDMQAAAABJRU5ErkJggg==");
}
