@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Rubik;
    font-weight: 300;
    src: url(../assets/fonts/Rubik-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: Rubik;
    font-weight: 400;
    src: url(../assets/fonts/Rubik-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Rubik;
    font-weight: 500;
    src: url(../assets/fonts/Rubik-Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: Rubik;
    font-weight: 600;
    src: url(../assets/fonts/Rubik-Bold.ttf) format("truetype");
  }
}

html,
body,
body > div,
body > div > div {
  @apply h-full;
  @apply font-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.siders-background {
  background: #03243d url(/img/backgrounds/Exclude.png) right center no-repeat !important;
}

.border-right-gray100 {
  border-right: 1px solid #d7dee3;
}

.border-top-tint80 {
  border-top: 1px solid #32526a;
}

.border-top-gray100 {
  border-top: 1px solid #d7dee3;
}

.border-bottom-gray100 {
  border-bottom: 1px solid #d7dee3;
}

.border-left-success-3px {
  border-left: 3px solid #50b83c;
}

.hover-border-left-tint80-3px:hover {
  border-left: 3px solid #32526a;
}

.email-settings-grid {
  grid-template-columns: 2fr repeat(3, minmax(0, 1fr));
}

.sidebar-grid {
  grid-template-rows: auto 80px;
}

.sidebar-grid-open {
  grid-template-rows: auto auto;
}

.api-key-grid {
  grid-template-columns: 80px auto 200px;
}

.subscriptions-grid {
  grid-template-columns: auto 170px 170px 170px 170px;
}

.apikeys-grid {
  grid-template-columns: 200px auto 200px 200px;
}

.logs-grid {
  grid-template-columns: auto 130px 130px auto 130px;
}

.demo-lookup-grid {
  grid-template-columns: 40px auto;
}

.footer-card-position {
  bottom: 90px;
  left: 0px;
}

.blur {
  filter: blur(0.2rem);
}

.fake-hidden {
  opacity: 0;
  height: 1px;
  width: 1px;
}

.dropdown-container {
  height: 41px;
}

/* Hide arrows for input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
